import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import bangabandhuolympiad from "../../img/bangabandhuolympiad.jpeg";
import laptopbd from "../../img/laptopbd.jpeg";
import netflix from "../../img/netflix.jpeg";
import bookrdr from "../../img/boordr.jpeg";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{ color: darkMode ? "white" : "" }}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <a href="https://bangabandhuolympiad.com/" target="_blank">
            <img
              style={{ width: "380px", height: "213px" }}
              src={bangabandhuolympiad}
              alt=""
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://helmetwala.genextbd.net/" target="_blank">
            <img
              style={{ width: "380px", height: "213px" }}
              src={laptopbd}
              alt=""
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://netflix-clone-67e7d.web.app/" target="_blank">
            <img
              style={{ width: "380px", height: "213px" }}
              src={netflix}
              alt=""
            />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://radiant-douhua-00269a.netlify.app/" target="_blank">
            <img
              style={{ width: "380px", height: "213px" }}
              src={bookrdr}
              alt=""
            />
          </a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
